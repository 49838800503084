import React from "react"

import styles from '@styles/pages/recruit.module.scss'
import Layout from "@comp/layout"
import SEO from '@comp/seo'

import BtnBox from '@comp/btn_box'
import PageTitle from '@comp/page_title'

// markup
const RecruitPage = ({ location }) => {
  return (
    <Layout pageName="recruit">
      <SEO
        title={`未来酒店店舗メンバー募集`}
        description={`株式会社未来酒店では現在、各店の店舗を募集しております。知名度やスペックだけではない、未だ知られざる魅力を持つ日本酒を一緒に紹介していきましょう。`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <PageTitle
            wrapClass={styles.title}
            label={"未来酒店\n店舗メンバー募集"}
            sub={"〜未来の基幹産業を共に！〜"}
          />
          <p className={styles.overview}>株式会社未来酒店では現在、各店の店舗を募集しております。知名度やスペックだけではない、未だ知られざる魅力を持つ日本酒を一緒に紹介していきましょう。</p>

          <div className={styles.contents}>
            <div className={styles.section}>
              <h2 className={styles.section__title}>未来日本酒店とは?</h2>
              <p className={styles.section__txt}>未来日本酒店のテーマは「知名度やスペックだけじゃない。コンセプトやストーリーで選んだ新しい”SAKEセレクトショップ”」広く流通する有名銘柄に限らず、味やコンセプトに新規性がありまさに未来の日本を代表していくことになる銘柄や、美味しい一方で地元限定流通であり都心部ではあまり手に入らないものなどを中心に、日本全国から取り寄せた160種類以上もの「未来型」の日本酒を取り揃えています。</p>
            </div>


            <section className={styles.section}>
              <h2 className={styles.section__title}>募集詳細</h2>

              <div className={styles.detail}>

                <section className={styles.detail__box}>
                  <h3 className={styles.title_h3}>社員</h3>
                  <div className={styles.detail__body}>
                    <div className={styles.detail__row}>
                      <h4 className={styles.title_h4}>ポジション</h4>
                      <p className={styles.detail__txt}>店舗担当</p>
                    </div>
                    <div className={styles.detail__row}>
                      <h4 className={styles.title_h4}>募集要項</h4>
                      <table className={styles.detail__table}>
                        <tbody>
                          <tr>
                            <th className={styles.detail__table__th}>仕事内容</th>
                            <td className={styles.detail__table__td}>
                              <p>未来日本酒店(未来酒店)各店での接客販売業務及び販売に付随する一般業務店舗業務に係わる戦略の計立案</p>
                            </td>
                          </tr>
                          <tr>
                            <th className={styles.detail__table__th}>求める人物像</th>
                            <td className={styles.detail__table__td}>
                              <ul>
                                <li>日本酒を中心とするお酒全般に関心や知見があり、接客やコミュニケーションを得意とする方</li>
                                <li>数字に基づく商売の基本的なアクションが行える方</li>
                                <li>基本的なPCスキルのある方</li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <th className={styles.detail__table__th}>歓迎</th>
                            <td className={styles.detail__table__td}>
                              <ul>
                                <li>接客もしくは飲食のホールまたはバー経験が1年以上ある方</li>
                                <li>酒類、流通業界経験者</li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <th className={styles.detail__table__th}>応募条件</th>
                            <td className={styles.detail__table__td}>
                              <ul>
                                <li>場所：株式会社未来酒店各店舗のいずれか(渋谷、二子玉川、桜木町)</li>
                                <li>雇用条件：週休2日制社会保険完備<br />
                                <small>※半年間の試用期間あり</small></li>
                                <li>報酬：経験能力、前職内容など考慮しにより決定(月給23万以上)<br />
                                <small>※実働の有無に関わらず30時間の時間外手当含む</small></li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>


                <section className={styles.detail__box}>
                  <h3 className={styles.title_h3}>アルバイト</h3>
                  <div className={styles.detail__body}>
                    <div className={styles.detail__row}>
                      <h4 className={styles.title_h4}>ポジション</h4>
                      <p className={styles.detail__txt}>店舗担当</p>
                    </div>
                    <div className={styles.detail__row}>
                      <h4 className={styles.title_h4}>募集要項</h4>
                      <table className={styles.detail__table}>
                        <tbody>
                          <tr>
                            <th className={styles.detail__table__th}>仕事内容</th>
                            <td className={styles.detail__table__td}>
                              <p>未来日本酒店(未来酒店)各店での接客販売業務及び販売に付随する一般業務</p>
                            </td>
                          </tr>
                          <tr>
                            <th className={styles.detail__table__th}>求める人物像</th>
                            <td className={styles.detail__table__td}>
                              <ul>
                                <li>日本酒を中心とするお酒全般に関心や知見があり、接客やコミュニケーションを得意とする方</li>
                                <li>数字に基づく商売の基本的なアクションが行える方</li>
                                <li>基本的なPCスキルのある方</li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <th className={styles.detail__table__th}>歓迎</th>
                            <td className={styles.detail__table__td}>
                              <ul>
                                <li>接客もしくは飲食のホールまたはバー経験が1年以上ある方</li>
                                <li>酒類、流通業界経験者</li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <th className={styles.detail__table__th}>応募条件</th>
                            <td className={styles.detail__table__td}>
                              <ul>
                                <li>場所：株式会社未来酒店各店舗のいずれか(渋谷、二子玉川、桜木町)</li>
                                <li>雇用条件：週3日以上の勤務(1日5時間以上)希望</li>
                                <li>報酬：応相談(時給1,100円以上)・交通費支給</li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
            </section>

            <section className={styles.section}>
              <h2 className={styles.title_h4}>問い合わせ先</h2>
              <p className={styles.section__txt}>ご不明な点などございましたら、下記フォームからお問い合わせください。</p>
              <div className={styles.contact}>
                <BtnBox
                  to={'/contact/'}
                >
                  ご応募はこちらから
                </BtnBox>
              </div>
            </section>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default RecruitPage
