
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'


const propTypes = {
  txt: PropTypes.string.isRequired
}

const Nl2br = ({
  txt
}) => {
  const formatText = useMemo( () => {
    const textArray = txt.split('\n')
    const len = textArray.length - 1
    return textArray.map((str, index) => (
      <React.Fragment key={index}>
        {str}
        {
          index !== len ? <br />: ''
        }        
      </React.Fragment>
    )
  )},[txt])

  return (
    <>{formatText}</>
  )
}

Nl2br.propTypes = propTypes
export default Nl2br