import React from "react"
import PropTypes from "prop-types"
import classNames from 'classnames'
import styles from '@styles/components/page_title.module.scss'
import Nl2br from "@comp/util/nl2br"

const propTypes = {
  wrapClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelEn: PropTypes.string,
  sub: PropTypes.string
}

const PageTitle = ({
  wrapClass,
  label,
  labelEn,
  sub
}) => {
  const classNameTitle = classNames([
    wrapClass,
    styles.title,
  ])

  return (
    <>
      <h1 className={classNameTitle}>
        <span className={styles.label}><Nl2br txt={label} /></span>
        {
          labelEn ? <span className={styles.label__en}><Nl2br txt={labelEn} /></span> : null
        }
        {
          sub ? <span className={styles.sub}><Nl2br txt={sub} /></span> : null
        }
      </h1>
    </>
  )
}

PageTitle.propTypes = propTypes
export default PageTitle
